import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Truncate component will shorten text with an ellipsis. Always add a `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute to the truncated element so the full text remains accessible.`}</p>
    <h2>{`Default example`}</h2>
    <p>{`Truncate will prevent text that overflows from wrapping. The default max-width is 125px.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Truncate title="Some text with a branch-name-that-is-really-long">
  Some text with a branch-name-that-is-really-long
</Truncate>
`}</code></pre>
    <h2>{`Custom max-width example`}</h2>
    <p>{`You can override the maximum width of the truncated text with the `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Truncate maxWidth={250} title="Some text with a branch-name-that-is-really-long">
  Some text with a branch-name-that-is-really-long
</Truncate>
`}</code></pre>
    <h2>{`Inline example`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`inline`}</inlineCode>{` boolean prop for inline (or inline-block) elements with a fixed maximum width (default: 125px).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`  Some text with a{' '}
  <Truncate as="strong" inline title="branch-name-that-is-really-long">
    branch-name-that-is-really-long
  </Truncate>
`}</code></pre>
    <h2>{`Expandable example`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`expandable`}</inlineCode>{` boolean prop to display the truncated text on hover.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Truncate expandable title="Some text with a branch-name-that-is-really-long">
  Some text with a branch-name-that-is-really-long
</Truncate>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Truncate components get `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{` and `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`div`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`125`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the max-width of the text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`inline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`displays text as inline block and vertical aligns to the top`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`expandable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`allows the truncated text to be displayed on hover`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      